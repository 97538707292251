.part_container {
  display: flex;
  position: relative;
  width: 40px;
  justify-content: center;
  background-color: transparent;
  padding: auto;
  margin-top: 0.5px;
  margin-bottom: 0.5px;
}
.part_image {
  /* width: 40px; */
  overflow: unset;
}

.part_point {
  position: absolute;
  background: #5c85f5;
  border-radius: 3px;
  width: 3px;
  height: 3px;
  top: calc(50% - 1.5px);
  margin-top: auto;
  margin-bottom: auto;
}
.part_point::before {
  content: ' ';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: rgba(92, 133, 245, 0.2);
  top: -1.5px;
  left: -1.5px;
}
.part_link {
  position: absolute;
  background: #5c85f5;
  width: 50px;
  top: calc(50% - 0.5px);
  height: 1px;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(26.5px);
}

.label_point {
  z-index: 1;
  position: absolute;
  top: calc(50% - 2px);
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #5c85f5;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(45px);
}
.label_point::before {
  z-index: 1;
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: rgba(92, 133, 245, 0.2);
  top: -2px;
  left: -2px;
}

.part_label {
  position: absolute;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  top: calc(50% - 16px);
  width: 84px;
  height: 32px;
  background: #414361;
  border-radius: 8px;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(87px);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px;
}

.part_label p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.part_image_container {
  display: flex;
  width: 40px;
  overflow: visible;
  justify-content: center;
}
