/*
  login 
*/
.headerIconLogout {
  margin-left: 12px;
  width: 25px;
  height: 25px;
  margin-top: 4px;
  cursor: pointer;
}
