/* 
  buttons
*/
.blueWhiteText {
  background-color: #5c85f5;
  color: #fff;
  padding: 4px 6px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  transition: color ease-out 0.1s;
  opacity: 0.8;
}

.blueWhiteText:hover {
  opacity: 1;
}
  