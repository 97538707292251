.form_radio input[type='radio'] {
  display: none;
}
.form_radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  /* padding-left: 25px; */
  /* margin-right: 0;
  line-height: 18px; */
  user-select: none;
}
.form_radio label:before {
  content: '';
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  /* left: -8px;
  top: -8px; */
  transform: translateY(3px);
  opacity: 0.7;
  border: 1px solid #737496;
  border-radius: 50%;
  transition: border-color ease-out 0.1s;
  box-sizing: border-box;
}

/* Checked */
.form_radio input[type='radio']:checked + label:before {
  background: url(./icons/radio-checked.svg) 0 0 no-repeat;
  border: 0px;
}

/* Hover */
.form_radio label:hover:before {
  border-color: #5c85f5;
}

/* Disabled */
.form_radio input[type='radio']:disabled + label:before {
  filter: grayscale(100%);
}

.radio-button input[type='radio'] {
  display: none;
}
.radio-button label {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  /* padding-left: 25px; */
  /* margin-right: 0;
  line-height: 18px; */
  user-select: none;
}
.radio-button label:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -8px;
  top: -8px;
  opacity: 0.7;
  border: 1px solid #737496;
  border-radius: 50%;
  transition: border-color ease-out 0.1s;
  box-sizing: border-box;
}

/* Checked */
.radio-button input[type='radio']:checked + label:before {
  background: url(./icons/radio.svg) 0 0 no-repeat;
  border: 0px;
}

/* Hover */
.radio-button label:hover:before {
  border-color: #5c85f5;
}

/* Disabled */
.radio-button input[type='radio']:disabled + label:before {
  filter: grayscale(100%);
}
