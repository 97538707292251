.part_container_bop {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  padding: auto;
  margin-top: 0.5px;
  margin-bottom: 0.5px;
}

.bop-part-grid {
  display: grid;
  grid-template-columns: 6rem 11.7rem 6rem;
}

.bop-part-right-side,
.bop-part-left-side {
  height: 100%;
}

.bop-part-vertical-line-left {
  border-left: 3px solid #a19f9f;
}

.bop-part-horizontal-line {
  border-bottom: 3px solid #a19f9f;
}

.bop-part-vertical-line-left {
  border-left: 3px solid #a19f9f;
}

.bop-part-vertical-line-right {
  border-right: 3px solid #a19f9f;
}

.bop-part-left-top, .bop-part-left-bottom,
.bop-part-right-top, .bop-part-right-bottom {
  height: 50%;
}


.part_connector_kill-line:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #a19f9f;
  left: -300%;
  width: 3px;

}
.ram_bg {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.shear_ram_top_slice,
.shear_ram_bottom_slice,
.pipe_ram_top_slice,
.pipe_ram_bottom_slice {
  overflow: unset;
  z-index: 0;
}

.shear_ram_stretching_bg,
.pipe_ram_stretching_bg {
  background: linear-gradient(90deg, #a3b2bb 0%, #f9fcfe 50.56%, #a3b2bb 100%);
  border: 1px solid #737c80;
  border-top: none;
  border-bottom: none;
  height: 100%;
  z-index: 0;
}

.pipe_ram_stretching_bg {
  min-height: 10px;
}

.pipe_ram_stretching_rod {
  position: absolute;
  background: #e5ebee;
  border: 1px solid #737c80;
  height: 100%;
  width: 10px;
}
.pipe_ram_stretching_rod.left {
  left: 29px;
}
.pipe_ram_stretching_rod.right {
  right: 29px;
}
.part_image_bop {
  /* width: 40px; */
  overflow: unset;
}
.part_image_bop.flexjoint {
  margin-left: -44;
}
.part_point_bop {
  position: absolute;
  background: #5c85f5;
  border-radius: 3px;
  width: 3px;
  height: 3px;
  top: calc(50% - 1.5px);
  margin-top: auto;
  margin-bottom: auto;
}
.part_point_bop::before {
  content: ' ';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: rgba(92, 133, 245, 0.2);
  top: -1.5px;
  left: -1.5px;
}
.part_link_bop {
  position: absolute;
  background: #5c85f5;
  width: 180px;
  top: calc(50% - 0.5px);
  height: 1px;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(91.5px);
}

.label_point_bop {
  z-index: 1;
  position: absolute;
  top: calc(50% - 2px);
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #5c85f5;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(180px);
}
.label_point_bop::before {
  z-index: 1;
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: rgba(92, 133, 245, 0.2);
  top: -2px;
  left: -2px;
}

.part_label_bop {
  position: absolute;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  top: calc(50% - 16px);
  width: 184px;
  height: 32px;
  background: #414361;
  border-radius: 8px;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(273px);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding: 8px;
}

.part_label_bop p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.part_image_container_bop {
  display: flex;
  width: 40px;
  overflow: visible;
  justify-content: center;
}
