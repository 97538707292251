@media print {
  @page {
      size: landscape !important;
      margin-top: 20mm !important;
  }
  @page:top  {
      @top-center {
          margin: 40pt;
          color: dimgray;
          border: solid silver;
          content: "Page " counter(page) " of " counter(pages);
      }
  }
  #bop {
    overflow: visible;
    height: 100%;
    zoom: 0.77;
  }
  .bopControls.bopControls { /* increased sepcificity to override */
      border: none;
  }
  .bopControls button {
    display: none;
  }
}

.bopControls {
  display: flex;
  border: 1px solid #353750;
  font-size: 14px;
  color: silver;
  width: fit-content;
  margin: 4px;
}

.bopControls button {
  color: #5c85f5;
  background-color: rgb(62, 64, 86);
  border-radius: 8px;
  border-style: none;
  outline: none;
  margin: 4px;
  cursor: pointer;
}

.bopControls label {
  margin: 4px;
}

.bopControls input {
  color: silver;
  text-align: center;
  margin: 4px;
}

/* bop table and controls */

:is(.bopControls, .bopTable) :is(input, select) {
  background-color: rgb(62, 64, 86);
  border-radius: 8px;
  border-style: none;
  outline: none;
}

:is(.bopControls, .bopTable) :is(input, select) :focus-visible {
  outline: none;
}

/*
  bop table
*/
.bopTable {
  border: none;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.bopTable .verticalStack {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
}

.bopTable .verticalStack div {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bopTable input {
  color: silver;
  font-size: 12px;
  width: calc(100% - 8px);
  padding: 4px;
  margin: 2px 4px 2px 4px;
}

.bopTable div.partType {
  text-align: left;
  color: white;
  width: calc(100% - 8px);
  margin: 2px 4px 2px 4px;
  padding: 4px;
  justify-content: flex-start;
}

.bopTable .selectContainer {
  width: calc(100% - 8px);
  margin: 2px 4px 2px 4px;
}

.bopTable select {
  color: white;
  font-size: 12px;
  width: 100%;
  padding: 4px;
  margin: 0;
  min-width: max-content;
  cursor: pointer;
}

.bopTable select option {
  background: rgb(62, 64, 86);
  color: white;
}

.bopTable button {
  border-radius: 8px;
  border-style: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.bopTable input[type=number] {
  text-align: center;
}
 
.bopTable .form_radio label:before {
  background-color: rgb(62, 64, 86);
}

.bopTable svg.disabled {
  filter: grayscale(100%);
}

.bopTable tbody {
  /* display: block; */
  overflow-y: scroll;
}

.bopTable thead th {
  color: silver;
  font-size: 11px;
  align-items: center;
  text-align: center;
  border-left: 1px solid #353750;
  border-right: 1px solid #353750;
}

.bopTable tbody td {
  color: silver;
  font-size: 12px;
  align-items: center;
  text-align: center;
  height: 1px; /* hack to get relative heights within to work properly */
}

.bopTable tbody tr {
  transition: background-color ease-out 0.1s;
}

.bopTable tbody tr td {
  transition: background-color ease-out 0.1s;
  border: 1px solid #353750;
}

.bopTable tbody tr:hover td {
  background-color: rgba(65, 67, 97, 1);
}

.bopTable thead tr th {
  background: rgb(62, 64, 86);
}

.bopTable thead tr th:first-child {
  border-left: none;
  border-radius: 8px 0px 0px 0px;
}

.bopTable thead tr th:last-child {
  border-right: none;
  border-radius: 0px 8px 0px 0px;
}

.bopTable tbody tr td:first-child {
  border-left: none;
  border-right: none;
}

.bopTable tbody tr td:last-child {
  border-right: none;
}

.bopTable tbody tr:last-child td {
  border-bottom: none;
}

.bopTable.bop tbody tr:last-child td {
  border-bottom: 1px solid #353750;
}

.bopTable tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 8px;
}

.bopTable tbody tr:last-child td:last-child {
  border-radius: 0px 0px 8px 0px;
}

