@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma SemiBold'), local('Axiforma-SemiBold'),
    url('Axiforma-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Thin'), local('Axiforma-Thin'),
    url('Axiforma-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Regular'), local('Axiforma-Regular'),
    url('Axiforma-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Medium Italic'), local('Axiforma-MediumItalic'),
    url('Axiforma-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Black'), local('Axiforma-Black'),
    url('Axiforma-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Black Italic'), local('Axiforma-BlackItalic'),
    url('Axiforma-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Heavy'), local('Axiforma-Heavy'),
    url('Axiforma-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Medium'), local('Axiforma-Medium'),
    url('Axiforma-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Bold Italic'), local('Axiforma-BoldItalic'),
    url('Axiforma-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma ExtraBold Italic'), local('Axiforma-ExtraBoldItalic'),
    url('Axiforma-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Book'), local('Axiforma-Book'),
    url('Axiforma-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Light'), local('Axiforma-Light'),
    url('Axiforma-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Thin Italic'), local('Axiforma-ThinItalic'),
    url('Axiforma-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Bold'), local('Axiforma-Bold'),
    url('Axiforma-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Light Italic'), local('Axiforma-LightItalic'),
    url('Axiforma-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma SemiBold Italic'), local('Axiforma-SemiBoldItalic'),
    url('Axiforma-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Italic'), local('Axiforma-Italic'),
    url('Axiforma-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Book Italic'), local('Axiforma-BookItalic'),
    url('Axiforma-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma ExtraBold'), local('Axiforma-ExtraBold'),
    url('Axiforma-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: local('Axiforma Heavy Italic'), local('Axiforma-HeavyItalic'),
    url('Axiforma-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
