@media print {
  /* @page { size: letter portrait !important } */
  DIV.killSheetWrapper {
    size: letter portrait !important
  }
  DIV.killSheetWrapper { 
    zoom: 0.9
  }
}

#killSheetWrapper INPUT {
  text-align: right;
  padding: 2px;
}
#killSheetCasingLiner::-webkit-scrollbar {
  width: 1em;
}
#killSheetCasingLiner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  height: 100%;
}
#killSheetCasingLiner::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, dodgerblue, rebeccapurple);
  outline: 1px solid slategrey;
}

DIV#killSlowPumpRate {
  gap: 1px;
  background-color: black;
  width: 100%;
}
DIV#killSlowPumpRate > DIV {
  background-color: #34354f;
  text-align: center;
  color: #D3D3D3;
}
DIV.killSheetDataTable DIV {
  text-align: right;
}
DIV.killSheetNumericCell {
  text-align: right;
  font-family: sans-serif, 'system-ui';
  line-height: 22px;
}
INPUT.killSheetDplCell {
  width: 75px;
  font-size: 13px;
}
INPUT.killSheetDplViewOnly {
  width: 100%;
  font-size: 13px;
  background-color: transparent;
  color: #737596;
}
.redBrackets {
  color: red;
  font-weight: 700;
}
.killSheetTableUnitHeader {
  color: #818589;
  font-weight: 700;
}

/* killsheet equation labels */
.blueCircularEquationLabel {
  width: 20px;
  height: 20px;
  line-height: 20px;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #1464F4;
  background-color: dodgerblue;
  color: #fff;
  text-align: center;
  display: inline-block;
  font-weight: 800;
}

.rightMargin10 {
  margin-right: 10px;
}

DIV.killSheetDataTableSubTotal {
  border-bottom: 2px solid #34354f !important;
}

DIV.killSheetDataTableTotal {
  background-color: #34354f;
  /* filter: invert(0%) sepia(98%) saturate(752%) hue-rotate(180deg) brightness(99%) contrast(91%); */
  color: #fff;
}

DIV.sectionHeader {
  /* background-color: #34354f; */
  /* filter: invert(0%) sepia(98%) saturate(752%) hue-rotate(180deg) brightness(99%) contrast(91%); */
  color: #fff;
  padding-top: 3px !important;
  padding-left: 2px !important;
}

DIV.totalRowContainer {
  display: flex;
  flex-direction: row;
  color: darkslategray;
  height: 25px;
  text-align: left;
  background-color: whitesmoke;
  border-bottom: 1px solid #2b2c40;
  /* align-content: start;
  justify-content: start;
  align-items: start; */
}

DIV.totalRowContent {
  flex: 13;
  height: 25px;
  text-align: left;
  font-size: 12pt;
  font-weight: 400;
}

.hollowLetter {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  border: solid 1px #1464F4;
  background-color: transparent;
  color: #000000;
  text-align: center;
  display: inline-block;
  font-weight: 800;
}
.blueLetter {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  border: solid 1px #1464F4;
  background-color: dodgerblue;
  color: #fff;
  text-align: center;
  display: inline-block;
  font-weight: 800;
}

#killSheetDataTable DIV,
#killSheetSummary DIV {
  font-family: sans-serif, 'system-ui';
}

/* kill table pipename tooltips */
[data-tooltip] {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /* padding: 4px; */
}
[data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #2F3048;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  /* font-family: sans-serif, 'system-ui'; */
  line-height: 1.4;
  min-width: 100px;
  text-align: center;
  border-radius: 4px;
}
[data-tooltip-position="right"]:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
[data-tooltip-position="right"]:before {
  left: 100%;
  margin-left: 6px;
}
[data-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
[data-tooltip-position="right"]:after {
  top: 50%;
  margin-top: -6px;
}
[data-tooltip-position="right"]:after {
  left: 100%;
  border-width: 6px 6px 6px 0;
  border-right-color: #000;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  z-index: 50;
}
.dataTooltipHost {
  white-space: nowrap;
  font-family: sans-serif, 'system-ui';
}

.madfdShiftImperial {
  text-align: right;
  margin-right: 5px !important;
}
.madfdShiftMetric {
  text-align: right;
  margin-right: 9px !important;
}
.marineRiserShift {
  margin-bottom: -20px;
}
