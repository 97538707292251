.btn {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  padding: 6px 9px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  max-height: 24px;
  color: #5c85f5;
  transition: background-color ease-out 0.1s;
  cursor: pointer;
}
.btn.import:hover {
  background-color: rgba(92, 133, 245, 0.116);
}
.btn.import:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.btn.import div {
  color: #5c85f5;
  white-space: nowrap;
}

.button {
  all: unset;
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px 32px 13px;
  background: #5c85f5;
  border-radius: 12px;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
  cursor: pointer;
}
.button.secondary {
  background: #34354f;
  color: #5c85f5;
}
.button.small {
  padding: 9px 16px 7px;
  height: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.button:hover {
  background-color: #4260b2;
}
.button.secondary:hover {
  background-color: #34354f;
  color: #4260b2;
}
.button:disabled {
  background-color: rgba(92, 133, 245, 0.15);
  color: rgba(92, 133, 245, 0.5);
}
.button.secondary:disabled {
  color: rgba(92, 133, 245, 0.5);
}
