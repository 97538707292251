/* 
  menu 
*/
.menuLinkWhite {
  width: 200px;
  padding-left: 24px;
  padding-bottom: 12px;
  padding-top: 28px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #4413b6;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.multiLineInput {
  width: 100%;
  height: 125px;
  background-color: #2d2f43;
  border: 1px solid #22233a;
  border-radius: 8px;
  padding: 10px;
  color: #fff;
}

.menuTextPad {
  margin-left: 4px;
}
