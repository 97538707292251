.field {
  position: relative;
  display: flex;
  font-size: 14px;
  margin-top: 28px;
  margin-bottom: 5px;
}

.field input,
.field select,
.field textarea {
  border: 1px dashed;
  border-color: #353750;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #2b2c41;
  padding: 8px 16px 5px 16px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  transition: border-color 0.2s ease-out;
}

.field input,
.field select {
  height: 40px;
}

.field textarea {
  line-height: 22px
}

.field input:hover,
.field select:hover,
.field textarea:hover {
  border-color: rgba(92, 133, 245, 0.8);
}

.field input:focus,
.field select:focus,
.field textarea:focus {
  border-color: #5c85f5;
  background-color: #fff;
  color: #000000;
}

.field .placeholder {
  font-style: italic;
  position: absolute;
  left: 16px;
  width: calc(100% - 24px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 21px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.field input.dirty + .placeholder,
.field input:focus + .placeholder,
.field input:not(:placeholder-shown) + .placeholder,
.field select.dirty + .placeholder,
.field select:focus + .placeholder,
.field select:not(:placeholder-shown) + .placeholder,
.field textarea.dirty + .placeholder,
.field textarea:focus + .placeholder,
.field textarea:not(:placeholder-shown) + .placeholder {
  top: -12px;
  left: 8px;
  font-size: 12px;
  line-height: 19px;
  color: #5c85f5;
}

.field input:disabled {
  background-color: #2b2c41;
  color: #fff;
  border-color: #353750;
}

.tools-table-last-row {
  border: 1px dashed;
  border-color: #353750;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #2b2c41;
  padding: 8px 16px 5px 16px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.tools-table-row input {
  border: 1px dashed;
  border-color: #353750;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #2b2c41;
  padding: 8px 16px 5px 16px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  transition: border-color 0.2s ease-out;
}

.tools-table-row input:hover {
  border: 1px dashed rgba(92, 133, 245, 0.8);
}

.tools-table-row input:focus {
  border-color: #5c85f5;
  color: #000000;
}

.tools-table-row input:-internal-autofill-selected,
.tools-table-row input:-webkit-autofill {
  background-color: #2D2E44 !important;
}
