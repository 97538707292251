.table.pipe {
  display: table;
  table-layout: fixed;
  background-color: #2d2e44;
  border-radius: 8px;
}

.table.pipe thead {
  display: table-header-group;
}

.table.pipe tbody {
  display: table-row-group;
}

.table.pipe thead tr th {
  height: 46px;
  font-size: 14px;
  text-align: left;
}

.table.pipe tbody tr td {
  height: 46px;
  font-size: 12px;
  text-align: left;
}
