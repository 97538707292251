/* regular */
.icon-button {
  all: unset;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: background-color ease-out 0.1s;
  cursor: pointer;
}
.icon-button.add-icon:hover {
  background-color: #1ade901e;
  cursor: pointer;
}
.icon-button.stash-icon:hover {
  background-color: #e8c8521a;
  cursor: pointer;
}
.icon-button.trash-icon:hover {
  background-color: #fd44441e;
  cursor: pointer;
}
.icon-button:disabled {
  opacity: 0.2;
  pointer-events: none;
}
/* small */
.small-icon-button {
  all: unset;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: background-color ease-out 0.1s;
  cursor: pointer;
}
.small-icon-button.edit-icon:hover {
  background-color: #fd44441e;
  cursor: pointer;
}
