@media print {
  @page {
    size: landscape !important;
    margin-bottom: 0.5in !important;
  }

  #trendSheetScrollButtonWrapper,
  .downloadButtonWrapper {
    display: none !important;
  }

  #trendSheet {
    overflow: visible !important;
    max-height: none !important;
  }

  #trendSheet .trendSheetRow:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  #trendSheet .trendSheetRow:first-child .trendSheetItem:first-child {
    border-top-left-radius: 8px;
  }

  #trendSheet .trendSheetRow:first-child .trendSheetItem:last-child {
    border-top-right-radius: 8px;
  }

  #trendSheet .trendSheetRow:first-child {
    border: 1px solid #484960 !important;
  }

  .editableTrendCellInput {
    font-size: 10px !important;
  }

  .trendSheetRow .trendSheetItem:first-child {
    display: none !important
  }

  #trendSheet .trendSheetRow:not(:first-child) .trendSheetItem:nth-child(2) .editableTrendCellInput {
    padding: 0 !important;
  }

  .trendSheetRow {
    page-break-inside: avoid;
    grid-template-columns:
    150px /* Time column */
    83px /* User column */
    50px /* Depth MD column */
    50px /* Depth TVD column */
    50px /* ROP column */
    50px /* WOB column */
    50px /* RPM column */
    50px /* Torque column */
    50px /* Pressure On column */
    50px /* Pressure Off column */
    50px /* Boost pressure column */
    50px /* SPM column */
    50px /* Flow In column */
    50px /* Flow Out column */
    50px /* Active column */
    50px /* MW In column */
    50px /* MW Out column */
    50px /* ECD column */
    50px /* Gas column */
    50px /* Up column */
    50px /* Free Rotation column */
    50px /* Down column */
    50px /* Azimuth column */
    50px /* Inclination column */
    125px !important /* Comment column */;
  }
}

/* data */
.editableTrendCellInput {
  border: none;
  outline: none;
  border-radius: 0px;
  min-width: 100%;
  width: 100%;
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 6px;
  text-align: left;
  height: 42px;
}
.editableTrendCellInput:focus,
.editableTrendCellInput:hover,
.editableTrendCellInput:active {
  background-color: #fff;
  color: #000000;
  border: none;
  border-radius: 0px;
  outline: none;
}
.editableTrendCellInput:hover {
  cursor: pointer;
}
::-webkit-calendar-picker-indicator {
  filter: invert(0.7);
  cursor: pointer;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* scrollbars */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-button {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: transparent;
  width: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
  width: 100px;
}

/* grid */
#trendSheet {
  margin-bottom: 16px;
  display: grid;
  overflow: auto;
  max-height: max(calc(100vh - 370px), 300px);
}
#trendSheetScrollButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  background-color: #42435c;
  padding: 8px 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.trendSheetScrollButton {
  height: 28px;
  width: 120px;
  border: 1px solid dimgray;
  background-color: #42435c;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
}
.trendSheetScrollButton:hover {
  background-color: #4b5e929a;
}
.trendSheetRow {
  display: grid;
  grid-gap: 0px;
  grid-template-columns:
    35px /* Delete column */
    180px /* Time column */
    100px /* User column */
    60px /* Depth MD column */
    60px /* Depth TVD column */
    60px /* ROP column */
    60px /* WOB column */
    60px /* RPM column */
    60px /* Torque column */
    60px /* Pressure On column */
    60px /* Pressure Off column */
    60px /* Boost pressure column */
    60px /* SPM column */
    60px /* Flow In column */
    60px /* Flow Out column */
    60px /* Active column */
    60px /* MW In column */
    60px /* MW Out column */
    60px /* ECD column */
    60px /* Gas column */
    60px /* Up column */
    60px /* Free Rotation column */
    60px /* Down column */
    60px /* Azimuth column */
    60px /* Inclination column */
    150px /* Comment column */;
  grid-auto-rows: minmax(min-content, max-content);
  width: 100%;
  font-size: 12px;
  padding: 0px;
  color: #737496;
  text-align: center;
  height: 42px;
}
#trendSheet .trendSheetRow:first-child {
  background-color: #42435c;
  color: dodgerblue;
  height: 50px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
  z-index: 2;
}
#trendSheet .trendSheetRow:first-child .trendSheetItem:first-child {
  z-index: 3;
}
#trendSheet .trendSheetRow:first-child .trendSheetItem:nth-child(2) {
  color: white;
  z-index: 3;
}
#trendSheet .trendSheetRow:not(:first-child) {
  border-bottom: 1px solid #484960;
  border-right: 1px solid #484960;
}
.trendSheetRow .trendSheetItem:first-child {
  left: 0px;
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #42435c;
}
.trendSheetRow .trendSheetItem:nth-child(2) {
  left: 35px;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  background-color: #42435c;
}
.trendSheetRow .trendSheetItem:nth-child(2) .editableTrendCellInput {
  padding-left: 6px;
}
