nav {
  white-space: nowrap;
  transition: all ease-in-out 0.2s;
}

nav.disabled {
  visibility: hidden;
  display: none;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li.menu_item {
  width: 200px;
  padding: 20px 24px;
  color: #737496;
  transition: all ease-out 0.1s;
  cursor: default;
  user-select: none;
  font-style: normal;
  font-size: 14px;
}

nav ul li.menu_item_wellSection {
  width: 200px;
  padding: 10px 24px;
  color: #737496;
  transition: all ease-out 0.1s;
  cursor: pointer;
  user-select: none;
  font-style: normal;
  font-size: 14px;
  padding-left: 28px;
}

nav ul li.menu_item_wellSection:hover {
  color: #fff;
}

nav ul li.menu_item a, a:visited {
  color: rgb(115, 116, 150);
  text-decoration: none;
}

nav ul li.menu_item a:hover {
  color: #fff;
}

nav ul li.menu_item_current {
  width: 200px;
  padding: 20px 24px;
  background-color: #414361;
  color: #fff;
  transition: all ease-out 0.1s;
  cursor: default;
  user-select: none;
  font-style: normal;
  font-size: 14px;
}

nav ul li.menu_item_hidden {
  display: none;
}

nav ul li.section_header {
  margin-left: 24px;
  padding-bottom: 12px;
  padding-top: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #515270;
  user-select: none;
  border-bottom: 1px solid #3c3d57;
}

nav ul li.section_separator {
  margin-left: 24px;
  border-bottom: 1px solid #3c3d57;
}

nav ul li.menu_item:hover {
  color: #fff;
  background-color: #41436162;
  cursor: pointer;
}

nav ul li.menu_item.active {
  color: #fff;
  background-color: #414361;
  box-shadow: 5px 0 0 #5c85f5 inset;
}

nav ul li.menu_item_wellSection.active::before {
  content: ' ';
  position: absolute;
  transform: translate(-24px, -14px);
  width: 5px;
  height: 40px;
  background: #5c85f5;
  border-radius: 0px 6px 6px 0px;
}

nav ul li.menu_item a#feedbackLink {
  display: block;
  padding: 20px 24px;
}
