/* 
  header
*/
#logoutButton {
  color: #5c85f5;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: color ease-out 0.1s;
  padding: 2px 8px;
}

#logoutButton:hover {
  background-color: #5c85f5;
  color: #fff;
  border-radius: 8px;
  padding: 2px 8px;
}
