.overpullCalculatorSettings {
  width: 100%;
  margin: 1rem 0;
  border-top: 1px solid #3d3d3d;
  border-radius: 8px 8px 8px 8px;
  padding: 1rem 0;
  font-size: 14px;
  color: #fff;
  max-width: 800px;;
}

.overpullCalculatorInput {
  background-color: rgb(62, 64, 86);
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  border: none;
}

.overpullCalculatorString {
  display: flex;
  flex-direction: column;
}

.overpullCalculatorCols {
  background-color: transparent;
  color: #fff;
  border: none;
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px dashed rgb(115, 117, 150);
  padding-bottom: 0.2rem;
  padding: 0.5rem 0;
}

.overpullCalculatorCols > div {
  display: flex;
  justify-content: flex-end;
  gap: 0.3rem;
}

.nowrap {
  white-space: nowrap;
}

.b700 {
  font-weight: 700;
}
.mb5 {
  margin-bottom: 5px;
}


.overpullCalculatorSectionHeader {
  color: dodgerblue;
  border-bottom: 1px solid dodgerblue;
  margin-bottom: 0.5rem;
  max-width: 800px;
}
.overpullCalculatorSectionHeader > h3 {
  color: dodgerblue;
  margin: 0.5rem 0;
}

.overpullCalculatorInput {
  background-color: rgb(62, 64, 86);
  font-weight: 700;
  min-height: 30px;
  max-height: 30px;
  text-align: right;
}
.overpullCalculatorInput:focus {
  background-color: silver;
  color: #000000;
  font-weight: 700;
  border: none !important;
  outline: none !important;
}
.overpullCalculatorSelect:focus {
  border: none !important;
  outline: none !important;
}


.overpullCalculatorTotalWrapper {
    background-color: rgb(229, 228, 226);
    font-size: 18px;
    color: #000;
    border-radius: 8px;
    border: 1px solid rgb(81, 82, 112);
    padding: 8px;
    max-width: 800px;
}

.overpullCalculatorTotalHeading {
  color: #000 !important;
  border: none;
  min-height: 30px;
  max-height: 30px;
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.overpullCalculatorTotalHeading > h3 {
  margin-top: 0;
}

.overpullCalculatorTotalCol {
  color: #000;
  border: none;
  font-size: 18px;
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px dashed rgb(115, 117, 150);
  padding-bottom: 0.2rem;
  padding: 0.5rem 0;
}

.overpullCalculatorTotalCol > div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.3rem;
}

.overpullCalculatorTotalCol > h3 {
  color: #000;
  margin-bottom: 0.5rem;
}
.overpullCalculatorTotalCol.total-col {
  background-color: rgba(255, 87, 121, 0.40);
}

.overpullCalculatorTotalLabel {
  color: rgb(115, 117, 150);
  font-size: 15px;
}

.overpullCalculatorGrid2col {
  display: grid;
  grid-template-columns: 5.5rem 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  align-items: center;
}

.overpullCalculatorContainer {
  margin-bottom: 4rem;
  margin-left: 8px;
}

.itemUnit {
  text-align: left;
  font-size: 14pt;
  color: #808080;
  text-transform: none;
}

@media print {
  .overpull-calculator-page > h1, 
  .overpullCalculatorCols,
  .overpullCalculatorGrid2col,
  .overpullCalculatorGrid2col .h25 {
    color: #272626;
  }

  .total-col {
    color: #272626;
    font-weight: 900;
  }
}
