/*
  page
*/
@media print {
  html {
    background-color: #2b2c41;
  }
  
  .pageWrapper {
    max-width: none !important;
  }

  .pageBody {
    overflow-y: visible;
    max-width: none !important;
  }
  
  .pageHeader {
    max-width: none !important;
  }

  .pageSidebar,
  .pageHomeButton {
    display: none !important;
  }
}

@media screen {
  .pageBody {
    overflow-y: auto;
  }
}

.pageWrapper {
  width: 100%;
  max-width: 1440px;
  height: 100vh;
  margin: auto;
  background-color: #2b2c41;
  display: flex;
  flex-direction: row;
  position: relative;
}

.pageSidebar {
  border-radius: 0px 32px 0px 0px;
  background-color: #2F3048;
  min-width: 200px;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pageSidebarBranding {
  background-color: #2F3048;
}

.pageHomeButton {
  position: absolute;
  top: 30px;
  left: 8px;
  color: white;
  z-index: 10;
}

.pageBody {
  border-radius: 0px 0px 0px 0px;
  background-color: #2b2c41;
  width: 100%;
}

.pageBodyContent {
  margin: 0px 20px 20px 20px;
}

.endeavorLogo {
  margin: 10px 34px;
}

.pageHeader {
  min-width: 1000px;
  height: 72px;
  padding: 20px 30px 12px 30px;
  background-color: transparent;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
