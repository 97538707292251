@media print {
    @page {
        size: landscape !important;
        margin-top: 20mm !important;
    }
    @page:top  {
        @top-center {
            margin: 40pt;
            color: dimgray;
            border: solid silver;
            content: "Page " counter(page) " of " counter(pages);
        }
    }
    DIV.grid {
      overflow: visible;
      height: 100%;
      zoom: 0.57;
    }
    DIV.tripSettings
    {
        display: none;
    }
    #tripTableHeader {
        position: relative;
        top: 0px;
        left: 0px;
        margin-bottom: 0px;
    }
}
/* settings */
.tripSettings {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 7% 19% 10% 32% 6% 26%;
    grid-template-rows: 100px;
    width: 100%;
    border-top: 1px solid #3d3d3d;
    border-radius: 8px 8px 8px 8px;
    font-size: 14px;
    color: dimgray;
    padding: 10px;
}
.tripContext {
    grid-column: 1 / 2;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: right;
}
.tripContextData {
    grid-column: 2 / 3;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: left;
    color: silver;
}
.tripLocation {
    grid-column: 3 / 4;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: right;
}
.tripLocationData {
    grid-column: 4 / 5;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: left;
    color: silver;
}
.tripClockAlarm {
    grid-column: 5 / 7;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: right;
}
.tripStats {
    grid-column: 5 / 6;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: right;
}
.tripStatsData {
    grid-column: 6 / 7;
    grid-row: 1 / 1;
    padding: 8px;
    text-align: left;
    color: silver;
}

/* toolbar */
.tripToolbar {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 20% 20% 60%;
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid #3d3d3d;
    border-radius: 8px 8px 8px 8px;
    font-size: 12pt;
    color: dimgray;
    padding: 10px;
}
.tripToolbarLeft {
    padding: 10px 10px 10px 20px;
}
/* data */
.tripTable {
    width: 100%;
    margin-bottom: 8px;
    border-radius: 8px 8px 8px 8px;
    font-size: 14px;
    color: silver;
    padding: 10px;
    width: 100%;
    background-color: transparent;
    font-size: 12px;
    display: table;
}
.tableCell {
    white-space: normal;
}
.headerCell {
    text-align: center;
    vertical-align: bottom;
}
.dataCell {
    color: silver;
    padding: 4px;
    border-top: 1px solid #3d3d3d;
    border-right: 1px solid #3d3d3d;
}
.editableDataCell {
    background-color: #fff;
    color: #000000;
}
.editableDataCellInput,
.editableDataCellInput:focus,
.editableDataCellInput:hover,
.editableDataCellInput:active {
    border: 1px solid transparent;
    outline: none;
}
.editableDataCellInput:focus,
.editableDataCellInput:active {
    border: 1px solid dodgerblue !important;
}
.editableDataCellSelect,
.editableDataCellSelect:focus,
.editableDataCellSelect:hover,
.editableDataCellSelect:active {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: #fff;
}
.tripTableHeader {
    display: grid;
    grid-gap: 0px;
    grid-template-columns:
        /* 4% 16.5% 5% 7% 7% 7% 6% 6% 5% 5% 5% 5% 5% 5% 11.5%; */
        minmax(50px, 100px)
        minmax(150px, 250px)
        minmax(40px, 40px)
        minmax(90px, 100px)
        minmax(90px, 100px)
        minmax(70px, 70px)
        minmax(80px, 80px)
        minmax(70px, 70px)
        minmax(80px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(150px, 250px);
    grid-auto-rows: minmax(min-content, max-content);
    width: 100%;
    font-size: 12px;
    padding: 0px;
    border: 1px solid transparent;
    text-align: center;
    background-color: #2e2f42;
}
.tripTableHeaderCell {
    margin: 0px;
    display: grid;
    align-self: end;
    border-right: 1px solid #3d3d3d;
    border-bottom: 1px solid #3d3d3d;
}
.tripTableRow {
    display: grid;
    grid-gap: 0px;
    grid-template-columns:
        /* 4% 16.5% 5% 7% 7% 7% 6% 6% 5% 5% 5% 5% 5% 5% 11.5%; */
        minmax(50px, 100px)
        minmax(150px, 250px)
        minmax(40px, 40px)
        minmax(90px, 100px)
        minmax(90px, 100px)
        minmax(70px, 70px)
        minmax(80px, 80px)
        minmax(70px, 70px)
        minmax(80px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(60px, 80px)
        minmax(150px, 250px);
    grid-auto-rows: minmax(min-content, max-content);
    width: 100%;
    font-size: 12px;
    padding: 0px;
    border: 1px solid transparent;
    color: #737496;
    text-align: center;
}
.tripTableRow:hover {
    background-color: #38394c;
}
.tripTableRowCell {
    margin: auto;
    padding-top: 3px;
    white-space: nowrap;
    height: 100%;
    width: 100%;
    display: grid;
    align-self: end;
    text-align: center;
    border-right: 1px solid #3d3d3d;
    border-bottom: 1px solid #3d3d3d;
}
.tripTableRowCellAlarm {
    background-color: #A70D2A;
    color: #fff;
    margin: auto;
    padding-top: 3px;
    white-space: nowrap;
    height: 100%;
    width: 100%;
    display: grid;
    align-self: end;
    text-align: center;
    border-right: 1px solid #3d3d3d;
    border-bottom: 1px solid #3d3d3d;
    border-radius: 8px;
}
.tripTableRowCellEditable {
    color: #fff;
}
.tripTableRowCellEditable:hover {
    border: 'none';
    outline: 'none';
}
.tripTableRowCellEditable:active {
    border: 'none';
    outline: 'none';
}
.tripTableRowCellEditable:focus {
    border: 'none';
    outline: 'none';
}
[type=checkbox] {
    width: 1rem;
    height: 1rem;
    color: dodgerblue;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #3e4056;
    transition: background 300ms;
    cursor: pointer;
}
[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
}
[type=checkbox]:checked {
    background-color: currentcolor;
}
[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}
[type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
}

/* tripsheet table */
TABLE.tripSheetTable {
    border-left: 1px solid #432831;
    border-top: 1px solid #432831;
}
TABLE.tripSheetTable td {
    border-right: 1px solid #432831;
    border-bottom: 1px solid #432831;
    padding: 4px;
    margin: 0px;
}

/* trip table pipename tooltips */
[data-tooltip] {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  [data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #fff;
    color: #2F3048;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.4;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
  }
  [data-tooltip-position="right"]:before {
    top: 50%;
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  [data-tooltip-position="right"]:before {
    left: 100%;
    margin-left: 6px;
  }
  [data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  [data-tooltip-position="right"]:after {
    top: 50%;
    margin-top: -6px;
  }
  [data-tooltip-position="right"]:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-right-color: #000;
  }
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    display: block;
    z-index: 50;
  }
  .dataTooltipHost {
    white-space: nowrap;
    font-family: sans-serif, 'system-ui';
  }

  SPAN.tripTableDescHover {
    position: relative;
    left: 0;
    overflow: hidden;
  }
  SPAN.tripTableDescHover:hover::after  {
    content: attr(data-title);
    padding: 5px;
    width: 110%;
    border: 1px solid #000;
    border-radius: 6px;
    position: absolute;
    top: -10px;
    left: 0px;
    background: #fff;
    color: #000000;
    z-index: 11;
  }

  .downloadButtonWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-content: flex-end;
  }
  .downloadButtonWrapper a, .downloadButtonWrapper a:visited {
    background-color: #38394c;
    color: #cacad0;
    border: 1px solid #3d3d3d;
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
  }

/* snapshot table */
.snpTable {
    border: 1px solid #000000;
}
.snapTableHeader {
    background-color: dodgerblue;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 9pt;
    padding: 3px 12px 3px 12px !important;
    width: auto;
    min-width: 40px !important;
    max-width: 60px !important;
}
.snapTableCell {
    background-color: #fff;
    color: #000000;
    font-weight: 400;
    text-align: center;
    font-size: 9pt;
    padding: 3px 12px 3px 12px !important;
    width: auto;
    min-width: 40px !important;
    max-width: 60px !important;
}
.snapTableLongTextField {
    background-color: #fff;
    color: #000000;
    font-weight: 400;
    text-align: left !important;
    font-size: 9pt;
    padding: 3px 3px 3px 3px !important;
    white-space: nowrap;
}
abbr#abbrDesc {
    position: relative;
    text-decoration: none;
}
abbr#abbrDesc:hover::after {
    background: #2b2c41;
    color: #fff;
    border-radius: 4px;
    bottom: -20%;
    content: attr(title);
    display: block;
    left: 100%;
    padding: 0.3em;
    padding-bottom: 1px;
    padding-left: 8px;
    position: absolute;
    width: 350px;
    height: 22px;
    z-index: 1;
}
/* abbr#abbrComment {
    position: relative;
    text-decoration: none;
}
abbr#abbrComment:hover::after {
    background: #000000;
    color: #fff;
    border-radius: 4px;
    bottom: 100%;
    content: attr(title);
    display: block;
    right: 100%;
    padding: 1em;
    position: absolute;
    width: 350px;
    height: 100px;
    z-index: 1;
    inline-size: 350px;
    overflow-wrap: break-word;
    overflow: visible;
} */
.snapshotTable {
    color: #fff;
    font-family: monospace;
    margin: 0px !important;
    padding: 3px !important;
    border-bottom: 1px solid black !important;
    text-align: right !important;
    font-size: 10px !important;
    font-weight: 600;
    height: 24px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.snapshotTableCell {
    white-space: nowrap;
    padding: 3px;
    font-size: 8px;
}
.snapLeft {
    text-align: left !important;
}
.snapRight {
    text-align: right !important;
}

/* snapshot tooltips */
[data-tooltips] {
    position: relative;
}

[data-tooltips]:before,
[data-tooltips]:after {
    display: none;
    position: absolute;
    top: -20px;
    left: -5px;
}

[data-tooltips]:before {
    border-bottom: .6em solid #2b2c41;
    /* border-bottom: .6em solid rgba(0,153,255,0.8); */
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    left: 0px;
    margin-top: 1em;
}

[data-tooltips]:after {
    background-color: #2b2c41;
    /* background-color: rgba(0,153,255,0.8); */
    border: 4px solid #2b2c41;
    /* border: 4px solid rgba(0,153,255,0.8); */
    border-radius: 7px;
    color: #ffffff;
    content: attr(data-tooltips-label) ":\A" attr(data-tooltips-message);
    left: -405px !important;
    margin-top: 1.5em;
    padding: 5px 15px;
    white-space: pre-wrap;
    width: 400px;
}

[data-tooltips]:hover:after,
[data-tooltips]:hover:before {
    display: block;
}
