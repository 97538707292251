.table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;
}

/* .dialog-open {
  overflow: hidden;
} */

/* .direction-picker {
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.direction-label {
  user-select: none;
  margin-left: 24px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
  transition: opacity 0.1s ease-out;
}

.direction-picker:hover .direction-label {
  opacity: 1;
} */

.drag-helper {
  width: 454px !important;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(65, 67, 97, 1);
  border-radius: 8px;
  pointer-events: none;
}

.drag-helper td {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #ffffff;
  padding-bottom: 4px;
  letter-spacing: 0.3px;
  text-align: center;
  padding-top: 14px;
}

.grabbing {
  cursor: grabbing;
}

/* .drag-helper {
  width: 355px;
} */
.drag-handle {
  cursor: grab;
  padding-top: 4px;
  width: 45px;
  padding-bottom: 4px !important;
}
.ip_delete {
  width: 43px;
}
.drag-helper .ip_serial {
  width: 336px !important;
}
.drag-helper .ip_length {
  width: 42px !important;
}
.drag-helper .drag-handle {
  display: none;
}
.drag-helper .ip_delete {
  display: none;
}
.exportButton {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 9px 6px 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  max-height: 24px;
  color: #5c85f5;
  transition: background-color ease-out 0.1s;
  cursor: pointer;
  text-decoration: none;
}
.exportButton:hover {
  background-color: rgba(92, 133, 245, 0.116);
}
.exportButton:disabled {
  opacity: 0.2;
  pointer-events: none;
}
.exportButton div {
  margin-top: 2px;
  color: #5c85f5;
}
.exportButton a,
.exportButton a:visited {
  color: #5c85f5;
  text-decoration: none;
  cursor: pointer;
}
.exportButton button {
  background-color: transparent;
  border: none;
  width: 60px;
  margin-left: 0px;
  cursor: pointer;
}
