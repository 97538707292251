  /*
    boxes
  */
  .uiContainer {
    background-color: transparent;
    border: 1px solid #44455f;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
    flex: auto;
    height: 250px;
    min-width: 280px;
    margin: 15px;
    flex: 1 1 220px;
  }

  .uiContainerShallow {
    height: 200px;
  }

  .uiContainerWide {
    height: 275px;
    min-width: 300px;
    flex: 1 1 900px;
  }

  .uiContainerShort {
    height: 180px;
    min-width: 300px;
    flex: 1 1 900px;
  }
  .uiContainerLimited {
    background-color: transparent;
    border: 1px solid #44455f;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
    flex: auto;
    height: 250px;
    min-width: 280px;
    max-width: 382px;
    margin: 15px;
    flex: 1 1 220px;
  }

  /*
    title
  */
  .uiContainerTitleWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .uiContainerTitle {
    color: rgb(202, 197, 197);
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 2px 8px;
    margin-bottom: 4px;
  }

  /*
    text
  */
  .uiContainerText {
    color: #948f8f;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
  }

  .uiContainerTextStatusActive {
    background: rgba(26, 222, 143, 0.1);
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #1ADE8F;
    padding: 0px 6px;
    height: 20px;
    margin-left: 9px;
  }

  .uiContainerTextStatusInactive {
    background: rgba(26, 222, 143, 0.1);
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #de1a44;
    padding: 0px 6px;
    height: 20px;
    margin-left: 9px;
  }

  /*
    button
  */
  .uiContainerButton {
    text-align: right;
    justify-content: end;
    margin-left: auto;

  }

  /*
    link
  */
  .uiContainerLinkLabel {
    color: rgb(110, 108, 108);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
  }

  .uiContainerLink {
    color: #5c85f5;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
  }

  .uiContainerLinkDisabled {
    color: rgb(143, 138, 138);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
  }

  A.uiContainerLink:hover {
    background-color: #5c85f5;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    transition: color ease-out 0.1s;
    padding: 2px 8px;
  }

  A.uiContainerLinkProminent {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #414361;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  A.uiContainerLinkProminent:hover {
    font-family: 'Plus Jakarta Sans', sans-serif;
    background-color: #5c85f5;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  A.uiContainerSecondaryLink {
    color: #6f6d6d;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
  }
  A.uiContainerSecondaryLink:hover {
    background-color: #5c85f5;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    transition: color ease-out 0.1s;
    padding: 2px 8px;
  }
