/*
    breadcrumbs
*/
.clickable_breadcrumb {
    cursor: pointer;
    transition: text-decoration ease-out 0.1s;
}
/* 
.clickable_breadcrumb:hover {
  cursor: pointer;
  text-decoration: underline;
} */
