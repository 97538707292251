.table.well {
  background-color: transparent;
  width: 100% !important;
}
.table.well thead tr th {
  height: 46px;
  border: none;
  font-size: 14px;
  text-align: left;
}

.table.well tbody tr td {
  height: 46px;
  border-right: none;
  border-left: none;
  font-size: 12px;
  text-align: left;
}

.table.well tbody tr {
  background-color: #2d2e44;
}



.table.well thead tr th {
  background-color: #2d2e44;
}
/*
  global styles
  TODO: move to /kit/styles
*/  

.formFieldRow {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  margin-bottom: 10px;
  background-color: #4260B2;
}

.formFieldRowDisplayOnly {
  border: 1px dashed silver;
  background-color: transparent;
}

.formFieldLabel {
  width: 125px;
  text-align: right;
  margin-right: 10px;
  background-color: transparent;
  color: #ffffff;
  margin-top: auto;
  margin-bottom: auto;
}

.formFieldInput {
  flex: 1;
  width: 150px;
  background-color: #ffffff;
  color: darkslategray;
  border: 1px dashed transparent;
  padding: 10px 15px 10px 5px;
  border-radius: 0px;
  font-weight: 700;
  text-align: right;
  height: 40px;
}

.formFieldInputViewOnly {
  background-color: #808080;
  color: black;
  border: none;
}

.formFieldInputClear {
  background-color: rgb(52, 53, 79);
  border-radius: 0px;
  border: none;
}

.formFieldSelect {
  flex: 1;
  background-color: transparent;
  color: darkslategray;
  font-weight: 700;
  border-radius: 6px;
  margin-left: 8px;
  border: 1px solid transparent;
}

.formFieldInputViewOnlyLabel {
  color: silver;
  background-color: transparent;
  text-align: right;
}

.formFieldInputViewOnlyText {
  background-color: transparent;
  color: #fff;
  text-align: left;
}

.wellStatusSelect:focus-visible {
 outline: none;
}

.sort-date-nav:hover {
  color: #ffffffa8;
  transition: color 0.65s ease-in-out;
}