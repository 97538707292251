@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './app/components/kit/styles/container.css';
@import './app/components/kit/styles/table.css';
@import './app/components/kit/styles/button.css';
@import './app/components/kit/styles/page.css';
@import './app/components/kit/styles/menu.css';
@import './app/components/kit/styles/dashboard.css';
@import './app/components/kit/styles/header.css';
@import './app/components/kit/styles/login.css';
@import './app/components/kit/styles/breadcrumb.css';

/* -------------------- INDEX -------------------- */
/*
BASE HTML PROPERTIES
  ALERT
  COLOUR
  GLOBAL ELEMENTS
  MEDIA QUERIES

HTML
  BUTTONS
  FORMS
  LINKLIST
  LINKS
  TABLES
  GRID TABLE
  METRIC TABLES

TEXT
  TEXT
  EDITABLE FIELD

WIDGETS
  APEX CHARTS

  BADGES
  CLOCK
  DIRECTION PICKER
  UNIT SELECTION CONTROLS

SECTIONS
  CREATE WELL SECTION
  DASHBOARD
  KICK CALCULATOR
  KILL SHEET
  LOGIN
  METRICS
  ROTATING HOURS SUMMARY
  ROTATING HOURS DETAIL
  ROTATING HOURS FILTERS
  WELL DASHBOARD
  WELL DATATABLE
  WELL SECTION DASHBOARD
  WELL SECTION LIST
  WELL SECTIONS TABLE

PATCHES
*/

*/* -------------------- BASE HTML PROPERTIES --------------------*/

html {
  filter: invert(0%);}

.App {
  background-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', 'Lexand Deca', sans-serif;
}

p,h1,h2,h3,h4,h5,h6 {
  color: #fff;
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', 'Lexand Deca', sans-serif;
  /* overflow: hidden; */
  background-color: #34354f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body *::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
}

body *::-webkit-scrollbar-track {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

body *::-webkit-scrollbar-thumb {
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ALERT */
.alert {
  background-color: orange;
  color: black;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: auto;
  text-align: center;
}

/* COLOUR */
.bgGreen {
  background-color: #009E60 !important;
}
.bgRed {
  background-color: #FF0000 !important;
}
.bgYellow {
  background-color: #FFFF00  !important;
  color: #000000  !important;
}

/* GLOBAL ELEMENTS */
.centeredDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.code {
  font-family: monospace;
}
.codeTable {
  font-family: monospace;
  margin: 0px !important;
  padding: 0px !important;
  border-bottom: 1px solid black !important;
  text-align: right !important;
  font-size: 13px !important;
  font-weight: 600;
  height: 24px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.dashed {
  text-decoration: underline;;
  text-decoration-style: dashed;
}
.disabledButVisibleButton {
  display: block;
  pointer-events: none;
  opacity: 0.5;
}
.dotted {
  text-decoration: underline;;
  text-decoration-style: dotted;
}
.failure {
  color: red;
}
.hidden {
  visibility: hidden;
}
.iconSvg {
  border: 'none';
}
.iconSvg:hover {
  filter: invert(80%);
}
.invisible {
  display: none;
}
.offline {
  background: transparent; /*rgb(250, 2, 2);*/
  border-radius: 50%;
  width: 33px;
  height: 15px;
  margin-left: 6px;
  margin-top: -20px;
}
.online {
  background: transparent; /*rgb(2, 242, 26);*/
  border-radius: 50%;
  width: 33px;
  height: 15px;
  margin-left: 6px;
  margin-top: -8px;
}
.popupAlertButton {
  border-radius: 8px;
  background-color: silver;
  border: 1px solid transparent;
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
}
.popupAlertButton:hover {
  background-color: rgb(92, 133, 245);
  color: #fff;
}
.preload {
  background: transparent;
}
.success {
  color: lime;
}
.systemFont {
  font-family: sans-serif, 'system-ui';
}
.visible {
  display: block;
}
.wait {
  cursor: wait;
}

/* MEDIA QUERIES */
@media print {
  body {
    display: table;
    table-layout: fixed;
  }

  #addString,
  #tableToolbar,
  #showHideSidebarButton, /* hide show/hide sidebar button in header when printing */
  #printButton, /* hide print button in header when printing */
  #saveButton, /* hide save button in header when printing */
  #logoutButton, /* hide logout button in header when printing */
  .MuiSnackbar-root, /* hide toast notifications when printing */
  .doNotPrint {
    display: none !important;
  }
}

@media screen {
  #addString,
  .doNotPrint {
    display: block !important;
  }
}

/* -------------------- HTML -------------------- */

/* BUTTONS */
.uiButtonStandard {
  all: unset;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px 10px;
  background: #5c85f5;
  border-radius: 8px;
  transition: background-color 0.1s ease-out;
}
.wideBlueButton {
  background: #009fd1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  height: 56px;
  justify-content: center;
  outline: none;
  padding: 10px 24px;
  text-decoration: none;
  transition: all .15s ease-in-out;
  width: 406px;
  margin-top: 25px;
}
.wideBlueButton:focus {
  box-shadow: 0 0 0 2px rgba(179, 179, 179, 0.5);
}
.wideBlueButton[disabled] {
  background-color: #94d4eb;
  cursor: not-allowed;
}
.wideBlueButtonDisabled {
  align-items: center;
  background: #94d4eb;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-size: 16px;
  height: 56px;
  justify-content: center;
  line-height: 17px;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  width: 406px;
  margin-top: 25px;
}

#showHideSidebarButton,
#saveButton,
#printButton {
  margin: 0; /* override default ChipTab style */
  cursor: pointer;
}

/* FORMS */
.formInputFieldLabel {
  font-size: 16px;
  text-align: left;
  justify-content: left;
  line-height: 15px;
  color: #1d2332;
  margin: 15px 0 8px 25px;
}
.formInputField {
  background-color: #fff !important;
  border: 1px solid silver !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  height: 44px !important;
  font-size: 16px !important;
  line-height: 24px;
  padding: 15px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: 0px;
  -webkit-appearance: none;
  outline: none;
  transition: border-color 0.2s ease-out;
  width: 408px;
}
.formInputField::-webkit-input-placeholder {
  color: gainsboro;
  font-style: italic;
  font-size: 14pt;
}
.formInputField::-moz-placeholder {
  color: gainsboro;
  font-style: italic;
  font-size: 14pt;
}
.formInputField:-ms-input-placeholder {
  color: gainsboro;
  font-style: italic;
  font-size: 14pt;
}
.formInputField:-moz-placeholder {
  color: gainsboro;
  font-style: italic;
  font-size: 14pt;
}

/* LINKLIST */
.ancillaryLinksWrapper {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.horizontalLinkListBlue a,
.horizontalLinkListBlue a:visited {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #009fd1;
  text-decoration: none;
  margin-right: auto;
  justify-content: center;
  text-align: center;
  width: 100%;
}

/* LINKS */
.currentSection {
  margin-left: 12px;
  width: 25px;
  height: 25px;
  margin-top: 4px;
  cursor: pointer;
}
.importantLink {
  background: transparent;
  color: #5c85f5;
  text-decoration: none;
}
.importantLink:hover {
  background: #5c85f5;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

/* TABLES*/
.table tbody tr {
  transition: background-color ease-out 0.1s;
  cursor: pointer;
}
.tbl {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-collapse: collapse;
  border-spacing: 0px;
  font-size: 15px;
}
.tbl thead {
  display:table-header-group;
  background-color: transparent;
}
.tbl thead th {
  text-align: right;
  font-weight: 600;
  color: dimgray;
}
.tbl tbody tr td {
  border: 1px solid transparent;
  border-collapse: collapse;
  text-align: right;
  padding: 10px;
  border-bottom: 1px solid dimgray;
}
.tbl tbody tr td:first-child {
  border: 1px solid transparent;
  border-collapse: collapse;
  text-align: right;
  padding: 5px;
  color: dimgray;
  font-weight: 700;
  border-bottom: 1px solid dimgray;
}
/* GRID TABLE */
.gridTable {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-template-rows: 1fr 1px 1fr;
  grid-auto-columns: minmax(200px, auto);
  grid-auto-rows: minmax(1px, auto);
  grid-gap: 30px;
  position: relative;
  background-color: transparent;
}
.gridTableOneRowOnly {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-template-rows: 1fr;
  grid-auto-columns: minmax(200px, auto);
  grid-auto-rows: minmax(1px, auto);
  grid-gap: 30px;
  position: relative;
  background-color: transparent;
}
.gridTableSection {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr 1px 1fr 1px 1fr;
  grid-template-rows: 1fr;
  grid-auto-columns: minmax(100px, auto);
  grid-auto-rows: minmax(1px, auto);
  grid-gap: 10px;
  position: relative;
  background-color: transparent;
}
.gridTableItemWrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: minmax(100px, auto);
  background-color: transparent;
  margin: auto;
}
.gridTableItemContainer {
  display: grid;
  grid-template-columns: 1fr 25px;
  grid-template-rows: 1fr;
  grid-auto-columns: minmax(100px, auto);
  margin: auto;
}
.gridTableItemValue {
  text-align: right;
  font-size: 24pt;
  background-color: transparent;
  color: white;
}
.gridTableItemUnit {
  text-align: left;
  font-size: 14pt;
  color: #808080;
  margin-bottom: auto;
  text-transform: none;
}
.gridTableItemLabel {
  text-align: 'center';
  font-size: 12pt;
  color: darkgray;
  white-space: nowrap;
  text-align: right;
}
.gridTableSpacerVertical {
  width: 1px;
  background-color: #404040;
  height: 95%;
}
.gridTableSpacerHorizontal {
  height: 1px;
  background-color: #404040;
}

/* METRIC TABLES */
.metricTable thead tr th,
.metricTable tbody tr td {
  width: 100%;
  overflow-wrap: break-word;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-collapse: collapse;
  border-spacing: 0px;
  font-size: 15px;
}
.metricTable thead th {
  text-align: right;
  font-weight: 600;
  color: dimgray;
}
.metricTable tbody tr td {
  border-collapse: collapse;
  text-align: right;
  padding: 10px;
  border-bottom: 1px solid dimgray;
}
.metricTable tbody tr td:first-child {
  border-collapse: collapse;
  text-align: right;
  padding: 5px;
  color: dimgray;
  font-weight: 700;
  border-bottom: 1px solid dimgray;
}
.metricTableRowContainer {
  display: flex;
  flex-direction: row;
  height: 26px;
  padding-top: 2px;
  border-bottom: 1px solid #899499;
}

.tabOut {
  color: yellow;
}
.hpChart {
  background-color: #708090;
  border-radius: 6px;
  color: #fff;
  border: 1px solid transparent;
  padding: 2px;
}
.hpChart:hover {
  background-color: dodgerblue;
}
.hpChart:active,
.hpChart:focus {
  border: 1px solid transparent;
  outline: none;
}
#dashboardChartROPContainer,
#dashboardChartWOBContainer {
  display: inline-block;
  min-width: 550px;
  width: auto;
}

/* -------------------- TEXT -------------------- */

/* TEXT */
.textHead {
  font-size: 42px;
  font-weight: 700;
  padding-bottom: 4px;
  color: #5c85f5;
}
.textGeneric {
  padding-top: 24px;
}
.importantLinkText {
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 0px 4px;
}
.importantLinkText:hover {
  text-decoration: none;
}

/* EDITABLE FIELD */
.editableInput {
  background-color: transparent;
  border: 1px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  padding: 0px 10px 0px 10px;
  outline: none;
}
.editableInput:focus {
  border-color: silver;
  background-color: white;
  color: black;
}
.editableInput:hover {
  border: 1px dashed dodgerblue;
  background-color: white;
  color: black;
}

/* -------------------- WIDGETS -------------------- */

/* APEX CHARTS */
.apexcharts-svg {
  background-color: transparent !important;
}

/* BADGES */
.uiContainerStatusActive {
  background: rgba(26, 222, 143, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #1ADE8F;
  padding: 4px 6px;
  margin-left: 8px;
}
.uiContainerStatusDownload {
  background: rgba(0, 150, 255, 0.3);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  padding: 4px 2px;
  margin-left: 8px !important;
}
.uiContainerStatusWarning {
  background: rgba(232, 199, 82, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #E8C752;
  padding: 4px 6px;
}
.uiContainerStatusMessage {
  background-color: rgba(26, 222, 143, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: cornflowerblue;
  padding: 4px 6px;
}
.uiContainerStatusNotification {
  background: rgba(233, 116, 81, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: hotpink;
  padding: 4px 6px;
}

/* CLOCK */
.timeclock {
  color: dimgray;
  margin-top: 23px;
  font-size: 16px;
}

/* DIRECTION PICKER */
.direction-picker {
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.direction-label {
  user-select: none;
  margin-left: 24px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
  transition: opacity 0.1s ease-out;
}

.direction-picker:hover .direction-label {
  opacity: 1;
}

/* UNIT SELECTION CONTROLS */
.unitSelectionWrapper {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
}
.unitSelectionContainer {
  padding: 0px 10px 10px 10px;
  margin-top: -20px;
  margin-bottom: 5px;
  font-size: 14px;
}
.unitSelectionPanel {
  padding: 15px;
  margin: 15px;
  border: 1px solid #E8C752;
  border-radius: 8px;
  background-color: #404040;
  display: none;
}
.unitSelectionTitle {
  color: #E8C752;
}
.unitSelectionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 15px 15px 5px 15px;
  font-size: 13px;
  background-color: transparent;
}
.unitSelectionRowItem {
  margin-right: 8px;
  width: 15%;
}
.unitSelectionPlaceholder {
  pointer-events: none;
}

/* -------------------- SECTIONS -------------------- */

/* CREATE WELL SECTION */
.createWellSectionWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: column;
}
.createWellSectionTitle {
  margin-left: 36;
  margin-bottom: 0;
  margin-top: 24;
  width: 100%;
}
.createWellSectionContainer {
  display: flex;
  width: 100%;
  margin-left: 52;
  margin-bottom: 16;
}
.createWellSectionBtnWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 34;
}
.inputModalField {
  border: 1px solid #353750;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #2b2c41;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.inputModalLabel {
  color: #737496;
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
}

/* DASHBOARD */
.dashboardSelect {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #2b2c41;
  padding: 0px 15px 0px 10px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  transition: border-color 0.2s ease-out;
  margin-left: 6px;
}
.dashboardSelect:hover{
  border-color: rgba(92, 133, 245, 0.8);
}

.dashboardSelect:focus {
  border-color: #5c85f5;
  background-color: #fff;
  color: #fff;
}
.dashboardSelect option {
  background-color: #34354f;
  color: #5c85f5;
  font-size: 14px;
}
.dashboardSelect option:active {
  background-color: #34354f;
}
.dashboardSelect option:hover {
  border-color: rgba(92, 133, 245, 0.8);
}

.dashboardStatusComplete {
  height: 25px;
  width: 25px;
  background-color: #1ADE8F;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.75;
}

.dashboardStatusInProgress {
  height: 15px;
  width: 15px;
  background-color: #E8C752;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.75;
}

/* KICK CALCULATOR */
.kickCalculatorSettings {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 16% 16% 16% 16% 16% 20%;
  grid-template-rows: 100px;
  width: 100%;
  margin-bottom: 8px;
  border-top: 1px solid #3d3d3d;
  border-radius: 8px 8px 8px 8px;
  font-size: 14px;
  color: dimgray;
  padding: 10px;
}
.kickCalculatorContextLabels {
  grid-column: 1 / 2;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: right;
  height: 20px;
}
.kickCalculatorContextValues {
  grid-column: 2 / 3;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: left;
  color: silver;
  height: 20px;
}
.kickCalculatorTypeLables {
  grid-column: 3 / 4;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: right;
  height: 20px;
}
.kickCalculatorTypeValues {
  grid-column: 4 / 5;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: left;
  color: silver;
  height: 20px;
}
.tripClockAlarm {
  grid-column: 5 / 7;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: right;
}
.kickCalculatorUserLabels {
  grid-column: 5 / 6;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: right;
  height: 20px;
}
.kickCalculatorUserValues {
  grid-column: 6 / 7;
  grid-row: 1 / 1;
  padding: 8px;
  text-align: left;
  color: silver;
  height: 20px;
}
.kickCalculatorInput {
  background-color: rgb(62, 64, 86);
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  border: none;
}
INPUT.kickCalculatorInputTableEdit {
  background-color: rgb(62, 64, 86);
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  border: none;
  width: 70px;
  margin-right: 10px;
  text-align: right;
}
INPUT.kickCalculatorInputTableEdit:hover {
  background-color: silver;
  color: #000000;
}
INPUT.kickCalculatorInputTableEdit:focus {
  background-color: silver;
  color: #000000;
  outline: none;
}
INPUT.kickCalculatorInputTableView {
  background-color: transparent;
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  border: none;
  width: 70px;
  margin-right: 10px;
  text-align: right;
}
INPUT.kickCalculatorInputTableView:hover {
  background-color: silver;
  color: #000000;
}
INPUT.kickCalculatorInputTableView:focus {
  background-color: silver;
  outline: none;
}
.kickCalculatorColumnHeadings {
  background-color: transparent;
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  border: none;
  min-height: 30px;
  max-height: 30px;
}
.h25 {
  height: 25px;
}
.toolsInput {
  background-color: transparent;
  min-height: 30px;
  max-height: 30px;
}
.toolsColumnHeader {
  padding: 5px;
  margin: 5px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  margin-bottom: 16px;
}
.nowrap {
  white-space: nowrap;
}
.sectionHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  grid-template-columns: 28% 7% 7% 7% 7% 7% 7% 7% 7% 7% 9%;
  color: #000000;
  font-size: 13px;
  border-bottom: 1px solid dimgray;
}
.kickCalculatorColumn {
  padding: 0px 5px 0px 5px;
  min-width: 85px;
  max-width: 85px;
  white-space: nowrap;
  margin: auto 0px auto 0px;
}
.b700 {
  font-weight: 700;
}
.mb5 {
  margin-bottom: 5px;
}
.kickCalculatorHeader {
  color: limegreen;
  font-size: 20px;
  font-weight: 700;
  min-height: 30px;
  max-height: 30px;
  padding: 4px 2px 0px 0px;
}
.kickCalculatorSubHeader {
  color: dodgerblue;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  min-height: 30px;
  max-height: 30px;
  padding: 4px 2px 0px 0px;
  border-bottom: 1px solid dodgerblue;
  width: 108%;
}
.kickCalculatorSectionHeader {
  color: lime;
  min-height: 30px;
  max-height: 30px;
  padding: 4px 2px 0px 0px;
}
.kickCalculatorInput {
  background-color: rgb(62, 64, 86);
  font-weight: 700;
  min-height: 30px;
  max-height: 30px;
}
.kickCalculatorInput:focus {
  background-color: silver;
  color: #000000;
  font-weight: 700;
  border: none !important;
  outline: none !important;
}
.kickCalculatorSelect:focus {
  border: none !important;
  outline: none !important;
}
.kickCalculatorOutput {
  min-height: 30px;
  max-height: 30px;
  margin-left: 4px;
  margin-top: 5px;
  margin-bottom: -5px;
}
.kickCalculatorSpacer {
  background-color: transparent;
  color: #fff;
  border-radius: 6px;;
  min-height: 30px;
  max-height: 30px;
}

/* KILLSHEET */
.source {
  background-color: transparent;
  border-radius: 4px;
  padding: 2px;
  font-size: 9px;
  margin-right: 4px;
  margin-top: 2px;
  height: 17px;
}
.source1 {
  color: aquamarine;
  border: 1px solid aquamarine;
}
.source2 {
  color: aqua;
  border: 1px solid aqua;
}
.source3 {
  color: chartreuse;
  border: 1px solid chartreuse;
}
.source4 {
  color: orange;
  border: 1px solid orange;
}
.source5 {
  background-color: indigo;
  color: white;
  font-weight: 700;
  border: 1px solid indigo;
}
.source6 {
  background-color: red;
  color: white;
  font-weight: 700;
  border: 1px solid red;
}
.source7 {
  background-color: dodgerblue;
  color: white;
  font-weight: 700;
  border: 1px solid dodgerblue;
}
.source8 {
  background-color: forestgreen;
  color: white;
  font-weight: 700;
  border: 1px solid forestgreen;
}
.sourceN {
  width: 50px;
  color: transparent;
}

INPUT#spmLabel1,
INPUT#spmLabel2,
INPUT#spmLabel3,
INPUT#spmLabel4,
INPUT#pressureLossDepth,
INPUT#pressureLossDate {
  background-color: transparent;
  font-size: 11pt;
  color: #fff;
}

/* LOGIN */
.loginWrapper {
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row-reverse;
}
.loginFormWrapper {
  margin-left: 40px;
  margin-right: 40px;
  background-color: transparent;
  justify-content: center;
  text-align: center;
}
.loginFormContainer {
  background-color: #fff;
  color: #000;
}
.loginWelcome {
  margin-bottom: 40px;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  color: #101f47;
  text-align: left;
  justify-content: left;
  width: 350px;
}
@media (min-width: 1101px) {
  .loginWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: url('./assets/images/login/login_bg_desktop.webp');
    background-position: center;
    background-size: cover;
  }
  .loginFormContainer {
    position: fixed;
    top: 0;
    right: 0;
    width: 534px;
    height: 100vh;
    min-height: 100vh;
  }
  .loginWelcome {
    margin-top: 140px;
    margin-left: 20px;
  }
}
@media (min-width: 601px) and (max-width: 1100px) {
  .loginWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: url('./assets/images/login/login_bg_desktop.webp');
    background-position: center;
    background-size: fill;
  }
  .loginFormContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    padding-bottom: 40px;
    width: 534px;
  }
  .loginWelcome {
    margin-top: 40px;
    margin-left: 40px;
  }
}
@media (max-width: 600px) {
  .loginWrapper {
    height: 208px;
    min-height: 208px;
    background-position: 50%;
    background: url('./assets/images/login/login_bg_desktop.webp');
    background-position: center;
    background-size: cover;
  }
  .loginFormContainer {
    position: fixed;
    top: 209px;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    margin: auto;
    padding-bottom: 40px;
    width: 100%;
    height: 100%;
  }
  .loginWelcome {
    margin-top: 35px;
    margin-left: 20px;
  }
}
.loginErrorMessaging {
  width: 406px;
  padding: 15px;
  border-radius: 8px;
  margin: 0px 0px 0px 0px;
  background-color: #fff;
  color: red;
  font-weight: 500;
  margin: auto;
  height: 35px !important;
  line-height: 35px !important;
}
.MuiStepIcon-active text {
  fill: #fff;
}

/* METRICS */
.metricsWrapper {
  background-color: #2D2E44;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-bottom: 20px;
}
.metricsContainer {
  width: 100%;
  padding: 30px;
  background-color: transparent;
}
.metricsPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
}
.metricItemProminentLabel {
  color: #769849;
  background-color: transparent;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 12pt;
  white-space: nowrap;
  text-align: right;
}
.metricItemProminentUnit {
  color: #4b6b22;
  text-align: left;
  font-size: 14pt;
  margin-bottom: auto;
}
.metricItemProminentValue {
  color: #82a05c;
  text-align: right;
  font-size: 24pt;
}
.metricItemProminentValueInput {
  background-color: transparent;
  border: 1px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  color: #82a05c;
  text-align: center;
  font-size: 24pt;
  padding: 0px 10px 0px 10px;
  outline: none;
  width: 200px;
}
.metricItemProminentValueInput:focus {
  border: 1px solid silver;
  background-color: white;
  color: black;
}
.metricItemProminentValueInput:hover {
  border: 1px dashed dodgerblue;
  background-color: white;
  color: black;
}
.metricItemValueInput {
  background-color: transparent;
  border: 1px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  font-size: 24pt;
  padding: 0px 10px 0px 10px;
  outline: none;
  width: 200px;
}
.metricItemValueInput:focus {
  border: 1px solid silver;
  background-color: white;
  color: black;
}
.metricItemValueInput:hover {
  border: 1px dashed dodgerblue;
  background-color: white;
  color: black;
}
.metricItemValueInputTable {
  background-color: transparent;
  border: 1px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  font-size: 14pt;
  padding: 0px 10px 0px 10px;
  outline: none;
  width: 125px;
}
.metricItemValueInputTable:focus {
  border: 1px solid silver;
  background-color: white;
  color: black;
}
.metricItemValueInputTable:hover {
  border: 1px dashed dodgerblue;
  background-color: white;
  color: black;
}
.metricItemValueInputDisabled {
  background-color: transparent;
  border: 1px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  font-size: 24pt;
  padding: 0px 10px 0px 10px;
  outline: none;
  width: 200px;
}
.metricItemValueInputDisabled:focus {
  border-color: transparent;
}
.metricItemValueInputDisabled:hover {
  border-color: transparent;
}
.metricItemValueInputTableDisabled {
  background-color: transparent;
  border: 1px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  font-size: 14pt;
  padding: 0px 10px 0px 10px;
  outline: none;
  width: 125px;
}
.metricItemValueInputTableDisabled:focus {
  border-color: transparent;
}
.metricItemValueInputTableDisabled:hover {
  border-color: transparent;
}

/* ROTATING HOURS SUMMARY */
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus {
  border: transparent;
}
#gridSummary .MuiDataGrid-pinnedColumns {
  background-color: #2b2c41;
}
#gridSummary .rh--header,
#gridSummary .MuiDataGrid-columnHeaders {
  font-weight: 700;
  background-color: #2b2c41;
  color: #fff;
  margin-bottom: 3px;
}
#gridSummary .MuiTablePagination-displayedRows,
#gridSummary .css-levciy-MuiTablePagination-displayedRows {
  color: dodgerblue;
  font-weight: 800;
}
#gridSummary .css-i4bv87-MuiSvgIcon-root {
  color: dodgerblue;
}
#gridSummary .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  background-color: transparent!important;
  width: 15px !important;
  height: 15px !important;
}
#gridSummary .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}
#gridSummary .MuiDataGrid-detailPanel,
#gridSummary .css-zil1cx-MuiDataGrid-detailPanel {
  color: #000000;
}
#gridSummary .MuiPaginationItem-circular,
#gridSummary .MuiPaginationItem-ellipsis {
  color: dodgerblue !important;
}
#gridSummary .MuiDataGrid-row {
  color: #fff !important;
}
#gridSummary .MuiDataGrid-cell
#gridSummary .MuiDataGrid-cell--textLeft
#gridSummary .MuiDataGrid-withBorderColor {
  border-bottom: none;
}
#gridSummary .MuiSvgIcon-root {
  color: #fff !important;
}
#gridSummary .iconReset {
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding-bottom: 2px;
  margin-left: 10px;
}

/* ROTATING HOURS DETAIL */
#gridDetails {
  background-color: #fff;
  border-radius: 10px;
}
#gridDetails .rh--header,
#gridDetails .MuiDataGrid-columnHeaders {
  font-weight: 700;
  background-color: #4a4b68;
  color: #fff;
  margin-bottom: 3px;
  border-radius: 0px;
}
#gridDetails .MuiTablePagination-displayedRows,
#gridDetails .css-levciy-MuiTablePagination-displayedRows {
  color: dodgerblue;
  font-weight: 800;
}
#gridDetails .css-i4bv87-MuiSvgIcon-root {
  color: dodgerblue;
}
#gridDetails .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  background-color: transparent!important;
  width: 15px !important;
  height: 15px !important;
}
#gridDetails .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}
#gridDetails .MuiDataGrid-detailPanel,
#gridDetails .css-zil1cx-MuiDataGrid-detailPanel {
  color: #000000;
}
#gridDetails .MuiPaginationItem-circular,
#gridDetails .MuiPaginationItem-ellipsis {
  color: dodgerblue !important;
}
#gridDetails .MuiDataGrid-row {
  color: #000000 !important;
}
#gridDetails .MuiDataGrid-rowCount {
  color: #000000;
}
#gridDetails .MuiDataGrid-toolbarContainer {
  background-color: #2b2c41;
}
#gridDetails .MuiSvgIcon-root {
  color: #fff !important;
}
#gridDetails DIV.MuiDataGrid-cell--withRenderer SVG.MuiSvgIcon-root {
  color: #8D918D !important;
}

/* ROTATING HOURS FILTERS */
.rhHeader SVG,
.rhHeader:hover {
  color: #fff !important;
}
.rhSelect {
  border-radius: 8px;
  background-color: dodgerblue;
  color: #fff;
  margin: 0px;
  padding: 7px;
}
#sectionTabs {
  margin-bottom: 14px;
}
#sectionTabs .activeTab,
#sectionTabs BUTTON,
#tubularsTabs BUTTON,
#wellTabs BUTTON {
  text-transform: capitalize !important;
}
#rhCbScopeWrapper .MuiFormControlLabel-label {
  font-size: 14px !important;
  margin-top: 10px;
}
#rhCbScopeWrapper .MuiFormGroup-root {
  margin-top: -6px;
}
#rhCbScopeWrapper .MuiFormControlLabel-root,
#rhCbScopeWrapper .MuiFormControlLabel-labelPlacementEnd {
  margin-bottom: -7px;
}

/* WELL DASHBOARD */
.wellInfoWrapper {
  width: 100%;
  color: #eeeeee;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid transparent;
  padding: 8px 8px 8px 16px;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 0px;
  margin-left: -13px;
}
.wellTabsWrapper {
  margin-top: 20px;
  margin-bottom: 0px;
  border-radius: 8px;
}
.wellTabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: -10px;
}
.wellInfoTimeClockWrapper{
  display: flex;
  flex-direction: row;
  width: 275px;
}
.killSheetTimeClockWrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: right;
}
.wellInfoTimeClock {
  margin-right: 20px;
  margin-top: 23px;
  color: dimgray;
  font-size: 16px;
}
.wellInfoStatusWrapper {
  margin-bottom: 33px;
  height: 34px;
}
.wellInfoUnitSelectionWrapper {
  margin-right: 8px;
  background-color: transparent;
  margin-top: 10px;
}
.wellInfoUnitSelection {
  text-align: right;
  padding-right: 3px;
  color: dimgray !important;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
}
.importantLinkProminent {
  height: 34px;
  border: 1px dashed dodgerblue;
  border-radius: 8px;
  padding: 4px 8px;
  margin-top: 17px;
}
.importantLinkProminentIcon {
  width: 15px;
  margin-right: 6px;
}

/* WELL DATATABLE */
.wellDatatableWrapper {
  background-color: #2D2E44;
  border: 1px solid transparent;
  border-radius: 8px;
}
.wellDatatableContainer {
  padding: 0px 10px 10px 10px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
}
.wellDatatablePanel {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.wellDatatable {
  background-color: 'transparent';
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px 5px 15px;
  font-size: 13px;
  color: silver;
}

/* WELL SECTION DASHBOARD */
.wellSectionBody {
  margin-top: 0px;
}
.wellSectionHeaderWrapper {
  margin-bottom: 0px;
  border-radius: 8px;
}
.wellSectionHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: -10px;
}
.wellSectionHeaderStatus {
  margin-bottom: 33px;
  height: 34px;
}
.wellSectionHeaderDropdownWrapper {
  margin-right: 8px;
  background-color: transparent;
  margin-top: -10px;
}
.wellSectionHeaderDropdown {
  text-align: right;
  padding-right: 3px;
  color: dimgray !important;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
}

select.wellSectionHeaderDropdown {
  color: darkgray !important;
}

/* WELL SECTION LIST */
.selectWellSection,
.selectWellSection:focus,
.selectWellSection:active,
.selectWellSection:hover {
  background-color: transparent;
  outline: none;
}
.selectWellSectionItem {
  padding: 3px 2px;
  cursor: pointer;
}
.selectWellSectionItem:checked {
  background-color: dodgerblue;
  color: white;
}
.selectWellSectionItem:focus option:checked {
  background: red linear-gradient(0deg, red 0%, red 100%);
}
.selectWellSectionItem:active,
.selectWellSectionItem:hover {
  background-color: dodgerblue;
  color: white;
}
.indentLeft {
  margin-left: 30px;
}
.invisibleSelection {
  visibility: hidden;
  margin-bottom: 10px;
}

/* WELL SECTIONS TABLE */
.wellSectionsTableCell {
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 64px !important;
}

/* -------------------- PATCHES -------------------- */
SPAN#addString BUTTON  DIV {
  white-space:  nowrap;
}
.MuiStepIcon-active text {
  fill: #fff;
}
.filter-blue{
  filter:
    invert(58%)
    sepia(178%)
    saturate(476%)
    hue-rotate(186deg)
    brightness(118%)
    contrast(119%)
}
