/*
  container
*/
.dashboardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3px;
  background-color: #2B2C41;
}

/*
  header
*/
.dashboardHeaderWelcome {
  color: #fff;
  margin-left: 10px;
}
.dashboardHeaderName {
  color: dodgerblue; /* #009fd1 */;
  font-weight: 800;
  margin-left: 9px;
}

/*
  footer
*/
.dashboardFooter {
  text-align: right;
  margin-right: 20px;
  padding-bottom: 20px;
  margin-top: 0px;
  color:rgb(110, 108, 108);
  font-style: italic;
  font-size: 10pt;
}
