@media print {
  @page {
    size: landscape !important;
    margin-bottom: 0.5in !important;

    @top-center {
      margin: 40pt;
      color: black;
      border: solid silver;
      content: 'Page ' counter(page) ' of ' counter(pages);
    }
  }

  #addOrCreateBHAbutton,
  #flipBHAbutton,
  #bhaGroupButton,
  #bhaUngroupButton,
  #bhaSaveToHistoryButton,
  #bhaRemoveFromHistoryButton,
  .bhaHistoricTable thead th:nth-of-type(1),
  .bhaHistoricTable tbody tr:first-of-type td:nth-of-type(1),
  .bhaCurrentTable thead th:nth-of-type(1),
  .bhaCurrentTable thead th:nth-of-type(2),
  .bhaCurrentTable thead th:nth-of-type(3),
  .bhaCurrentTable tbody tr:first-of-type td:nth-of-type(1),
  .bhaCurrentTable tbody tr:first-of-type td:nth-of-type(2),
  .bhaCurrentTable tbody tr:first-of-type td:nth-of-type(3) {
    display: none !important;
  }

  #historyEntrySelection {
    border-color: #5c85f5 !important;
  }

  .bhaTable th #shearableLegend {
    display: inline;
  }

  .bhaTable tbody.shearable td .shearableIndicator {
    display: block;
    position: absolute;
    right: -4px;
    top: -6px;
    font-size: 18px;
  }

  .bhaTable tbody.unshearable td .shearableIndicator {
    display: none;
  }

  .bhaTable tbody {
    break-before: auto;
    break-after: auto;
  }

  .bhaTable tbody,
  .bhaTable th,
  .bhaTable tr,
  .bhaTable td {
    break-inside: avoid-page;
  }

  .bhaTable thead th {
    border-top: 1px solid #353750;
    border-right: 1px solid #353750;
  }

  .bhaCurrentTable thead th:nth-of-type(4),
  .bhaHistoricTable thead th:nth-of-type(2) {
    border-left: 1px solid #353750;
    border-top-left-radius: 8px;
  }

  .bhaTable thead th:last-of-type {
    border-top-right-radius: 8px;
  }

  .bhaTable tbody td {
    border-top: 1px solid #353750;
    border-right: 1px solid #353750;
  }

  .bhaCurrentTable tbody tr:first-of-type td:nth-of-type(4),
  .bhaHistoricTable tbody tr:first-of-type td:nth-of-type(2) {
    border-left: 1px solid #353750;
  }

  .bhaTable tbody:last-of-type td:not(.topInSplit) {
    border-bottom: 1px solid #353750;
  }

  .bhaCurrentTable tbody:last-of-type tr:first-of-type td:nth-of-type(4),
  .bhaHistoricTable tbody:last-of-type tr:first-of-type td:nth-of-type(2) {
    border-bottom-left-radius: 8px;
  }

  .bhaTable tbody:last-of-type tr:first-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
  }

  .bhaTable tbody.bha-group-top tr:first-of-type td {
    border-top: 3px solid #4260b2;
  }
  
  .bhaTable tbody.bha-group-bottom tr:first-of-type td:not(.topInSplit),
  .bhaTable tbody.bha-group-bottom tr:last-of-type td {
    border-bottom: 3px solid #4260b2;
  }
  
  .bhaCurrentTable tbody.bha-group-top tr:first-of-type td:nth-of-type(4),
  .bhaCurrentTable tbody.bha-group-middle tr:first-of-type td:nth-of-type(4),
  .bhaCurrentTable tbody.bha-group-bottom tr:first-of-type td:nth-of-type(4),
  .bhaHistoricTable tbody.bha-group-top tr:first-of-type td:nth-of-type(2),
  .bhaHistoricTable tbody.bha-group-middle tr:first-of-type td:nth-of-type(2),
  .bhaHistoricTable tbody.bha-group-bottom tr:first-of-type td:nth-of-type(2) {
    border-left: 3px solid #4260b2;
  }
  
  .bhaTable tbody.bha-group-top tr:first-of-type td:last-of-type,
  .bhaTable tbody.bha-group-middle tr:first-of-type td:last-of-type,
  .bhaTable tbody.bha-group-bottom tr:first-of-type td:last-of-type {
    border-right: 3px solid #4260b2;
  }
}

@media screen {
  .bhaTable th #shearableLegend {
    display: none
  }

  .bhaTable tbody td .shearableIndicator {
    display: none
  }

  .bhaTable thead th {
    border-top: 1px solid #353750;
    border-right: 1px solid #353750;
  }

  .bhaTable thead th:first-of-type {
    border-left: 1px solid #353750;
    border-top-left-radius: 8px;
  }

  .bhaTable thead th:last-of-type {
    border-top-right-radius: 8px;
  }

  .bhaTable tbody td {
    border-top: 1px solid #353750;
    border-right: 1px solid #353750;
  }

  .bhaTable tbody tr:first-of-type td:first-of-type {
    border-left: 1px solid #353750;
  }

  .bhaTable tbody:last-of-type td:not(.topInSplit) {
    border-bottom: 1px solid #353750;
  }

  .bhaTable tbody:last-of-type tr:first-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
  }

  .bhaTable tbody:last-of-type tr:first-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
  }

  .bhaTable tbody.bha-group-top tr:first-of-type td {
    border-top: 3px solid #4260b2;
  }
  
  .bhaTable tbody.bha-group-bottom tr:first-of-type td:not(.topInSplit),
  .bhaTable tbody.bha-group-bottom tr:last-of-type td {
    border-bottom: 3px solid #4260b2;
  }
  
  .bhaTable tbody.bha-group-top tr:first-of-type td:first-of-type,
  .bhaTable tbody.bha-group-middle tr:first-of-type td:first-of-type,
  .bhaTable tbody.bha-group-bottom tr:first-of-type td:first-of-type {
    border-left: 3px solid #4260b2;
  }
  
  .bhaTable tbody.bha-group-top tr:first-of-type td:last-of-type,
  .bhaTable tbody.bha-group-middle tr:first-of-type td:last-of-type,
  .bhaTable tbody.bha-group-bottom tr:first-of-type td:last-of-type {
    border-right: 3px solid #4260b2;
  }
}

#addOrCreateBHAbutton {
  height: 28px;
  width: 100%;
  background: #2f3047;
  border: 1px solid #353750;
  border-radius: 6px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
}

#flipBHAbutton {
  height: 28px;
  width: 100%;
  background: #2f3047;
  border: 1px solid #353750;
  border-radius: 6px;
  display: flex;
  padding: 0;
  transition: all 0.2s ease-out;
}

#bhaGroupButton,
#bhaUngroupButton,
#bhaSaveToHistoryButton,
#bhaRemoveFromHistoryButton {
  text-wrap: nowrap;
}

#historyEntrySelection {
  text-align: center;
  width: 200px;
}

/*
  bha table
*/
.bhaTableWrapper {
  display: block;
  overflow-x: auto;
  height: fit-content;
}

.bhaTable {
  display: table;
  border: none;
  padding-bottom: 16px;
  border-spacing: 0;
  border-collapse: separate;
}

.bhaTable thead {
  display: block;
  width: 100%;
}

.bhaPartComment {
  width: 100%;
  max-height: 50px;
  overflow-y: auto;
}

.bhaTable tbody td {
  line-height: 16px;
  align-items: center;
  text-align: center;
  padding: 6px 6px;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #ffffff;
}

.bhaTable tbody td {
  transition: background-color ease-out 0.1s;
}

.bhaTable tbody:hover td {
  background-color: rgba(65, 67, 97, 1);;
}

.bhaTable tbody.shearable td {
  background-color: #48df440e;
}

.bhaTable tbody.shearable:hover td {
  background-color: #48df444e;
}

.bhaTable tbody.unshearable td {
  background-color: #fd44440e;
}

.bhaTable tbody.unshearable:hover td {
  background-color: #fd44444e;
}

.bhaTable thead th {
  background: #2f3047;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #737496;
  padding: 8px 12px;
}
